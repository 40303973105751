.sidebar-menu {
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 275px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--background-color);
  transform: translateX(-275px);
  transition: all 0.15s linear;
  z-index: 10001;

  &__root {
    display: none;
  }

  &__wrapper {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background-color: transparent;
    transition: background-color 0.2s linear;
    transition-delay: 0.1s;
  }

  &__content {
    overflow-y: scroll;
    overflow: auto;
    height: 100%;
    width: 100%;
  }

  &__section {
    display: flex;
    flex-direction: column;
    padding: 4px 0;
    border-top: 1px solid hsla(var(--primary-text-color_hsl), 0.15);

    &--borderless {
      margin: 0;
      border-top: 0;
    }
  }

  @media (max-width: 400px) {
    width: 90vw;
  }
}

.sidebar-menu__root--visible {
  display: block;

  .sidebar-menu {
    transform: translateX(0);
  }

  .sidebar-menu__wrapper {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.sidebar-menu-header {
  display: flex;
  height: 46px;
  padding: 12px 14px;
  border-bottom: 1px solid hsla(var(--primary-text-color_hsl), 0.15);
  box-sizing: border-box;
  align-items: center;

  &__title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    color: var(--primary-text-color);
  }

  &__btn {
    margin-left: auto;
  }
}

.sidebar-menu-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px 18px;
  box-sizing: border-box;

  &__avatar {
    display: block;
    width: 56px;
    height: 56px;
  }

  &__name {
    display: block;
    margin-top: 10px;

    .display-name__account {
      display: block;
      margin-top: 2px;
      color: var(--primary-text-color--faint);
    }
  }

  &__stats {
    display: flex;
    margin-top: 12px;
  }
}

.sidebar-menu-profile-stat {
  display: flex;
  font-size: 14px;
  text-decoration: none;

  &:not(:first-of-type) {
    margin-left: 18px;
  }

  &__value {
    display: flex;
    margin-right: 3px;
    font-weight: 700;
    color: var(--primary-text-color);
  }

  &__label {
    display: flex;
    color: var(--primary-text-color--faint);
  }

  &:hover {
    text-decoration: underline;
  }
}

.sidebar-menu-item {
  display: flex;
  padding: 16px 18px;
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-text-color--faint);
  font-size: 15px;
  font-weight: 400;

  &:hover {
    background-color: hsla(var(--brand-color_hsl), 0.1);
    color: var(--primary-text-color);
  }

  .fa {
    margin-right: 10px;
  }

  &:hover {
    &__title {
      color: var(--primary-text-color);
    }
  }
}

.sidebar-menu .wtf-panel {
  box-shadow: none !important;
  margin: 0;
}
