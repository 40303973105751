.audio-error-cover {
  align-items: center;
  background: var(--background-color);
  color: var(--primary-text-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 8px;
  position: relative;
  text-align: center;
  z-index: 100;
}

.status__audio-player {
  background: var(--background-color);
  box-sizing: border-box;
  cursor: default; /* May not be needed */
  margin-top: 8px;
  overflow: hidden;
  position: relative;
}

.status__audio-player-audio {
  height: 100%;
  object-fit: cover;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
}

.status__audio-player-expand,
.status__audio-player-mute {
  color: var(--primary-text-color);
  opacity: 0.8;
  position: absolute;
  right: 4px;
  text-shadow: 0 1px 1px $base-shadow-color, 1px 0 1px $base-shadow-color;
}

.status__audio-player-expand {
  bottom: 4px;
  z-index: 100;
}

.status__audio-player-mute {
  top: 4px;
  z-index: 5;
}

.detailed,
.fullscreen {
  .audio-player__volume__current,
  .audio-player__volume::before {
    bottom: 27px;
  }

  .audio-player__volume__handle {
    bottom: 23px;
  }

}

.audio-player {
  overflow: hidden;
  position: relative;
  background: $base-shadow-color;
  max-width: 100%;
  border-radius: 4px;
  height: 57px;

  &.warning_visible {
    height: 92px;
  }

  &:focus {
    outline: 0;
  }

  audio {
    max-width: 100vw;
    max-height: 80vh;
    min-height: 120px;
    object-fit: contain;
    z-index: 1;
  }

  &.fullscreen {
    width: 100% !important;
    height: 100% !important;
    margin: 0;

    audio {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
    }
  }

  &.inline {
    audio {
      object-fit: contain;
      position: relative;
    }
  }

  &__controls {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba($base-shadow-color, 0.85) 0, rgba($base-shadow-color, 0.45) 60%, transparent);
    padding: 0 15px;
    opacity: 1;
    transition: opacity .1s ease;
  }

  &.inactive {
    min-height: 57px;

    audio,
    .audio-player__controls {
      visibility: hidden;
    }
  }

  &__spoiler {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    border: 0;
    background: var(--background-color);
    color: var(--primary-text-color--faint);
    transition: none;
    pointer-events: auto;

    &:hover,
    &:active,
    &:focus {
      color: var(--primary-text-color);
    }

    &__title {
      display: block;
      font-size: 14px;
    }

    &__subtitle {
      display: block;
      font-size: 11px;
      font-weight: 500;
    }
  }

  &__buttons-bar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  &__spoiler-warning {
    font-size: 16px;
    white-space: nowrap;
    overlow: hidden;
    text-overflow: ellipsis;
    background: hsl(var(--brand-color_h), var(--brand-color_s), 20%);
    padding: 5px;

    &__label {
      color: #ffffff;
    }

    button {
      background: transparent;
      font-size: 16px;
      border: 0;
      color: rgba(#ffffff, 0.75);
      position: absolute;
      right: 0;
      padding-right: 5px;

      i {
        margin-right: 0;
      }

      &:active,
      &:hover,
      &:focus {
        color: #ffffff;
      }
    }
  }

  &__buttons {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.left {
      button {
        padding-left: 0;
      }
    }

    button {
      background: transparent;
      padding: 2px 10px;
      font-size: 16px;
      border: 0;
      color: rgba(#ffffff, 0.75);

      &:active,
      &:hover,
      &:focus {
        color: #ffffff;
      }
    }
  }

  &__time-sep,
  &__time-total,
  &__time-current {
    font-size: 14px;
    font-weight: 500;
  }

  &__time-current {
    color: #ffffff;
    margin-left: 60px;
  }

  &__time-sep {
    display: inline-block;
    margin: 0 6px;
  }

  &__time-sep,
  &__time-total {
    color: #ffffff;
  }

  &__volume {
    cursor: pointer;
    height: 24px;
    display: inline;

    &::before {
      content: "";
      width: 50px;
      background: rgba(#ffffff, 0.35);
      border-radius: 4px;
      display: block;
      position: absolute;
      height: 4px;
      left: 85px;
      bottom: 20px;
    }

    &__current {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 4px;
      left: 85px;
      bottom: 20px;
      background: var(--brand-color);
    }

    &__handle {
      position: absolute;
      z-index: 3;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      bottom: 16px;
      left: 70px;
      transition: opacity .1s ease;
      background: var(--brand-color);
      box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);
      pointer-events: none;
    }
  }

  &__link {
    padding: 2px 10px;

    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__seek {
    cursor: pointer;
    height: 24px;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      background: rgba(#ffffff, 0.35);
      border-radius: 4px;
      display: block;
      position: absolute;
      height: 4px;
      top: 10px;
    }

    &__progress,
    &__buffer {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 4px;
      top: 10px;
      background: var(--brand-color);
    }

    &__buffer {
      background: rgba(#ffffff, 0.2);
    }

    &__handle {
      position: absolute;
      z-index: 3;
      opacity: 1;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      top: 6px;
      margin-left: -6px;
      transition: opacity .1s ease;
      background: var(--brand-color);
      box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);
      pointer-events: none;
    }

    &:hover {
      .audio-player__seek__handle {
        opacity: 1;
      }
    }
  }

  &.detailed {
    width: 100vmin;
    height: 80px;

    @media screen and (max-width: 790px) { width: 80vmin; }

    .audio-player__buttons {
      button {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.media-spoiler-audio {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-top: 8px;
  position: relative;
  border: 0;
  display: block;
}

.media-spoiler-audio-play-icon {
  border-radius: 100px;
  color: var(--primary-text-color--faint);
  font-size: 36px;
  left: 50%;
  padding: 5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
