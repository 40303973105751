.wtf-panel {
  @include standard-panel-shadow;
  display: flex;
  width: 100%;
  border-radius: 10px;
  flex-direction: column;
  height: auto;
  box-sizing: border-box;
  background: var(--foreground-color);

  &:first-child {
    margin-top: 0;
  }

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .wtf-panel-header {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    padding: 15px 15px 0;

    &__icon {
      margin-right: 10px;
    }

    &__label {
      flex: 1 1;
      color: var(--primary-text-color);
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
    }
  }

  &__content {
    width: 100%;
    padding-top: 8px;
  }

  &__list {
    padding: 0 5px;
  }

  &__subtitle {
    display: block;
    padding: 0 15px;
    color: var(--primary-text-color--faint);
  }

  &__form {
    display: block;
    padding: 15px;

    &.button {
      width: 100%;
    }
  }

  .wtf-panel-list-item {
    display: block;
    padding-bottom: 10px;

    &:not(:first-of-type) {
      margin-top: 12px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--brand-color--med);
    }

    &__content {
      display: flex;
      flex-direction: row;
      min-height: 46px;
      margin-left: 58px;
    }

    &__account-block {
      display: flex;
      position: relative;
      align-items: baseline;
      padding-right: 10px;

    &__avatar {
      height: 46px;
      width: 46px;
      background-color: #ff0000;
      left: -58px;
      position: absolute;
    }

    &__name {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 6px;

      &__name {
        color: var(--primary-text-color);
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        margin-bottom: 2px;
        max-height: 32px; //2 lines of text
        overflow: hidden;
      }

      &__username {
        color: var(--highlight-text-color);
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  &__follow-block {
    margin-left: auto;
    padding-top: 6px;

    &__button {
      display: flex;
    }

    &__icon {
      color: var(--primary-text-color);
      }
    }
  }
}
