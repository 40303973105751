.getting-started__wrapper,
.getting-started,
.flex-spacer {
  background: var(--brand-color--med);
}

.getting-started__wrapper {
  flex: 0 0 auto;
}

.flex-spacer {
  flex: 1 1 auto;
}

.getting-started {
  overflow: auto;

  &__footer {
    flex: 0 0 auto;
    padding: 10px;
    padding-top: 20px;

    ul {
      margin-bottom: 10px;
    }

    ul li {
      display: inline;
    }

    ul li::after {
      content: ' · ';
    }

    ul li:last-child::after {
      content: '';
    }

    p {
      color: hsla(var(--primary-text-color_hsl), 0.8);
      font-size: 13px;
      margin-bottom: 20px;

      a {
        color: hsla(var(--primary-text-color_hsl), 0.4);
        text-decoration: underline;
      }
    }

    a {
      text-decoration: none;
      color: hsla(var(--primary-text-color_hsl), 0.4);

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__trends {
    background: var(--brand-color--med);
    flex: 0 1 auto;

    @media screen and (max-height: 810px) {
      .trends__item:nth-child(3) {
        display: none;
      }
    }

    @media screen and (max-height: 720px) {
      .trends__item:nth-child(2) {
        display: none;
      }
    }

    @media screen and (max-height: 670px) {
      display: none;
    }
  }
}
