.security-settings-panel {
  margin: 20px;

  h1.security-settings-panel__setup-otp {
    font-size: 20px;
    line-height: 1.25;
    color: var(--primary-text-color);
    font-weight: 600;
  }

  h2.security-settings-panel__setup-otp {
      display: block;
      font-size: 16px;
      line-height: 1.5;
      color: var(--primary-text-color--faint);
      font-weight: 400;
    }

  div {
    display: block;
    margin: 10px 0;
  }

  .security-warning {
    color: var(--primary-text-color);
    padding: 15px 20px;
    font-size: 14px;
    background-color: var(--warning-color--faint);
    margin: 5px 20px;
    border-radius: 8px;
    margin: 20px auto;
  }

  .backup_codes {
    margin: 20px;
    font-weight: bold;
    padding: 15px 20px;
    font-size: 14px;
    background-color: var(--brand-color--faint);
    border-radius: 8px;
    margin: 20px;
    text-align: center;
    position: relative;
    min-height: 125px;

    .backup_code {
      margin: 5px auto;
    }

    .loading-indicator {
      position: absolute;
    }
  }

  .security-settings-panel__setup-otp__buttons {
    margin: 20px;
    display: flex;
    justify-content: space-between;

    .button {
      min-width: 182px;
    }
  }

  div.confirm-key {
    display: block;
    font-size: 16px;
    line-height: 1.5;
    color: var(--primary-text-color--faint);
    font-weight: 400;
    margin: 0 0 20px 20px;
  }
}

form.otp-auth {
  .error-box {
    width: 100%;
    text-align: center;
    color: $error-red;
  }
}
