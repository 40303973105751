$no-columns-breakpoint: 600px;

code {
  font-family: var(--font-monospace), monospace;
  font-weight: 400;
}

.form-container {
  max-width: 400px;
  padding: 20px;
  margin: 0 auto;
}

.simple_form {
  .input {
    margin-bottom: 8px;
    overflow: hidden;

    &.hidden {
      margin: 0;
    }

    &.radio_buttons {
      .radio {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .radio > label {
        position: relative;
        padding-left: 28px;

        input {
          position: absolute;
          top: -2px;
          left: 0;
        }
      }
    }

    &.boolean {
      position: relative;

      .label_input > label {
        font-family: inherit;
        font-size: 14px;
        padding-top: 5px;
        color: var(--primary-text-color);
        display: block;
        width: auto;
      }

      .label_input,
      .hint {
        padding-left: 28px;
      }

      .label_input__wrapper {
        position: static;
      }

      input[type="checkbox"] {
        position: absolute;
        top: 3px;
        left: 0;
      }

      label a {
        color: var(--highlight-text-color);
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  .row {
    display: flex;
    margin: 0 -5px;

    .input {
      box-sizing: border-box;
      flex: 1 1 auto;
      width: 50%;
      padding: 0 5px;
    }
  }

  .hint {
    color: var(--primary-text-color--faint);

    a {
      color: var(--highlight-text-color);
    }

    code {
      border-radius: 3px;
      padding: 0.2em 0.4em;
      background: var(--background-color);
    }
  }

  span.hint {
    display: block;
    font-size: 12px;
  }

  p.hint {
    margin-bottom: 15px;
    color: var(--primary-text-color--faint);

    &.subtle-hint {
      text-align: center;
      font-size: 12px;
      line-height: 18px;
      margin-top: 15px;
      margin-bottom: 0;
    }
  }

  .card {
    margin-bottom: 15px;
  }

  strong {
    font-weight: 500;

    @each $lang in $cjk-langs {
      &:lang(#{$lang}) {
        font-weight: 700;
      }
    }
  }

  .input.with_floating_label {
    .label_input {
      display: flex;

      & > label {
        font-family: inherit;
        font-size: 14px;
        color: var(--primary-text-color);
        font-weight: 500;
        min-width: 150px;
        flex: 0 0 auto;
      }

      input,
      select {
        flex: 1 1 auto;
      }
    }

    &.select .hint {
      margin-top: 6px;
      margin-left: 150px;
    }
  }

  .input.with_label {
    .label_input > label {
      font-family: inherit;
      font-size: 14px;
      color: var(--primary-text-color);
      display: block;
      word-wrap: break-word;
      font-weight: 500;
    }

    ul {
      flex: 390px;
    }
  }

  .input.with_block_label {
    max-width: none;

    & > label {
      font-family: inherit;
      font-size: 16px;
      color: var(--primary-text-color);
      display: block;
      font-weight: 500;
      padding-top: 5px;
    }

    .hint {
      margin-bottom: 15px;
    }

    ul {
      columns: 2;
    }
  }

  .required abbr {
    text-decoration: none;
    color: lighten($error-value-color, 12%);
  }

  .fields-group {
    margin-bottom: 25px;

    .input:last-child {
      margin-bottom: 0;
    }
  }

  .fields-row {
    display: flex;
    margin: 0 -10px;
    padding-top: 5px;
    margin-bottom: 25px;

    .input {
      max-width: none;
    }

    &__column {
      box-sizing: border-box;
      padding: 0 10px;
      flex: 1 1 auto;
      min-height: 1px;

      &-6 {
        max-width: 50%;
      }
    }

    .fields-group:last-child,
    .fields-row__column.fields-group {
      margin-bottom: 0;
    }

    @media screen and (max-width: $no-columns-breakpoint) {
      display: block;
      margin-bottom: 0;

      &__column {
        max-width: none;
      }

      .fields-group:last-child,
      .fields-row__column.fields-group,
      .fields-row__column {
        margin-bottom: 25px;
      }
    }
  }

  .input.radio_buttons .radio label {
    margin-bottom: 5px;
    font-family: inherit;
    font-size: 14px;
    color: var(--primary-text-color);
    display: block;
    width: auto;
  }

  .check_boxes {
    .checkbox {
      label {
        font-family: inherit;
        font-size: 14px;
        color: var(--primary-text-color);
        display: inline-block;
        width: auto;
        position: relative;
        padding-top: 5px;
        padding-left: 25px;
        flex: 1 1 auto;
      }

      input[type=checkbox] {
        position: absolute;
        left: 0;
        top: 5px;
        margin: 0;
      }
    }
  }

  fieldset[disabled] {
    input[type=text],
    input[type=number],
    input[type=email],
    input[type=password],
    textarea {
      color: var(--primary-text-color--faint);
      border-color: var(--primary-text-color--faint);
    }
  }

  input[type=text],
  input[type=number],
  input[type=email],
  input[type=password],
  textarea {
    box-sizing: border-box;
    font-size: 16px;
    color: var(--primary-text-color);
    display: block;
    width: 100%;
    outline: 0;
    font-family: inherit;
    resize: vertical;
    background: var(--background-color);
    border: 1px solid var(--highlight-text-color);
    border-radius: 4px;
    padding: 10px;
    transition: 0.2s;

    &:invalid {
      box-shadow: none;
    }

    &:focus:invalid:not(:placeholder-shown) {
      border-color: lighten($error-red, 12%);
    }

    &:required:valid {
      border-color: $valid-value-color;
    }

    &:active,
    &:focus {
      border-color: var(--brand-color--hicontrast);
    }
  }

  input[type=text][disabled],
  input[type=number][disabled],
  input[type=email][disabled],
  input[type=password][disabled],
  textarea[disabled] {
    color: var(--primary-text-color--faint);
    border-color: var(--primary-text-color--faint);
  }

  .input.field_with_errors {
    label {
      color: lighten($error-red, 12%);
    }

    input[type=text],
    input[type=number],
    input[type=email],
    input[type=password],
    textarea,
    select {
      border-color: lighten($error-red, 12%);
    }

    .error {
      display: block;
      font-weight: 500;
      color: lighten($error-red, 12%);
      margin-top: 4px;
    }
  }

  .input.disabled {
    opacity: 0.5;
  }

  .actions {
    margin-top: 30px;
    display: flex;

    &.actions--top {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  button,
  .button,
  .block-button,
  .color-swatch {
    display: block;
    width: 100%;
    border: 0;
    border-radius: 4px;
    background: var(--brand-color);
    color: #fff;
    font-size: 18px;
    line-height: inherit;
    height: auto;
    padding: 10px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 500;
    outline: 0;
    margin-bottom: 10px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: var(--brand-color--hicontrast);
    }

    &.negative {
      background: $error-value-color;

      &:hover {
        background-color: lighten($error-value-color, 5%);
      }

      &:active,
      &:focus {
        background-color: darken($error-value-color, 5%);
      }
    }

    &.accordion__toggle {
      display: inline-block;
      width: auto;
      border: 0;
      border-radius: 0;
      background: none;
      color: var(--primary-text-color--faint);
      font-size: 18px;
      line-height: inherit;
      height: auto;
      padding: 0 10px;
      text-transform: none;
      text-decoration: none;
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;
      font-weight: 500;
      outline: 0;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  select {
    appearance: none;
    box-sizing: border-box;
    font-size: 16px;
    color: var(--primary-text-color);
    background: var(--background-color);
    display: block;
    width: 100%;
    outline: 0;
    font-family: inherit;
    resize: vertical;
    border: 1px solid var(--highlight-text-color);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 30px;
    height: 41px;
    position: relative;
    margin-top: 8px;
    cursor: pointer;
  }

  .select-wrapper {
    display: flex;
    align-items: center;

    &::after {
      display: flex;
      align-items: center;
      font-family: "ForkAwesome";
      content: "";
      position: absolute;
      right: 12px;
      height: calc(100% - 8px);
      padding-left: 12px;
      pointer-events: none;
      margin-top: 8px;
    }
  }

  .label_input {

    &__color {
      display: inline-flex;
      font-size: 14px;

      .color-swatch {
        width: 32px;
        height: 16px;
        margin-left: 12px;
      }
    }

    &__wrapper {
      position: relative;
    }

    &__append {
      position: absolute;
      right: 3px;
      top: 1px;
      padding: 10px;
      padding-bottom: 9px;
      font-size: 16px;
      color: var(--primary-text-color);
      font-family: inherit;
      pointer-events: none;
      cursor: default;
      max-width: 140px;
      white-space: nowrap;
      overflow: hidden;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 1px;
        width: 5px;
        background-image: linear-gradient(to right, hsla(var(--background-color_hsl), 0), var(--background-color));
      }
    }
  }

  &__overlay-area {
    position: relative;

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--background-color);
      backdrop-filter: blur(2px);
      border-radius: 4px;

      &__content {
        text-align: center;

        &.rich-formatting {
          &,
          p {
            color: var(--primary-text-color);
          }
        }
      }
    }
  }

  h2 {
    font-size: 20px;
    line-height: normal;
    margin-bottom: 14px;
    font-weight: bold;
  }
}

.block-icon {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  font-size: 24px;
}

.qr-code {
  flex: 0 0 auto;
  background: var(--foreground-color);
  padding: 4px;
  margin: 0 10px 20px 0;
  box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);
  display: inline-block;

  svg {
    display: block;
    margin: 0;
  }
}

.simple_form {
  .warning {
    box-sizing: border-box;
    background: rgba($error-value-color, 0.5);
    color: var(--primary-text-color);
    text-shadow: 1px 1px 0 rgba($base-shadow-color, 0.3);
    box-shadow: 0 2px 6px rgba($base-shadow-color, 0.4);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;

    a {
      color: var(--primary-text-color);
      text-decoration: underline;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }

    strong {
      font-weight: 600;
      display: block;
      margin-bottom: 5px;

      @each $lang in $cjk-langs {
        &:lang(#{$lang}) {
          font-weight: 700;
        }
      }

      .fa {
        font-weight: 400;
      }
    }
  }
}

.columns-area {
  form.simple_form {
    padding: 15px;
  }
}

.captcha {
  background-color: #ffffff;
  border-radius: 4px;

  img {
    display: table;
    margin: 0 auto;
  }

  input[type="text"] {
    border-radius: 0 0 4px 4px;
  }
}

.authtokens {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
}

.authtoken {
  &__app-name {
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__valid-until {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__revoke {
    margin-top: 10px;
  }
}

.file-picker img {
  max-width: 100px;
  max-height: 100px;
}

.code-editor {
  textarea {
    font-family: monospace;
    white-space: pre;
  }

  &--invalid textarea {
    border-color: $error-red !important;
    color: $error-red;
  }

  .input {
    margin-bottom: 0;
  }

  .hint {
    margin-top: 10px;
  }
}

.input .row .fa-times-circle {
  position: absolute;
  right: 7px;
  cursor: pointer;
  color: $error-red;
  transform: translateY(9px);
}

.site-preview {
  border-radius: 4px;
  overflow: hidden;
  height: 164px;
  border: 1px solid;
  margin-bottom: 40px;
  background: var(--background-color);

  * {
    z-index: 0;
  }

  a {
    cursor: default;
  }

  .ui {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;
  }

  .page {
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.input.with_label.toggle .label_input {
  display: flex;
  font-size: 14px;
  align-items: center;

  .theme-toggle {
    margin-left: 10px;
  }
}

.actions.add-row {
  margin: 10px 0 0;

  .button {
    border: 0;
    background: transparent;

    &:hover {
      color: var(--primary-text-color);
    }
  }
}
