// Roboto Regular
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/roboto/roboto-regular-400.eot?#iefix');
  src: url('../fonts/roboto/roboto-regular-400.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-regular-400.woff2') format('woff2'),
  url('../fonts/roboto/roboto-regular-400.woff') format('woff'),
  url('../fonts/roboto/roboto-regular-400.ttf') format('truetype'),
  url('../fonts/roboto/roboto-regular-400.svg') format('svg');
}

// Roboto Regular Italic
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/roboto/roboto-regular-italic-400.eot?#iefix');
  src: url('../fonts/roboto/roboto-regular-italic-400.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-regular-italic-400.woff2') format('woff2'),
  url('../fonts/roboto/roboto-regular-italic-400.woff') format('woff'),
  url('../fonts/roboto/roboto-regular-italic-400.ttf') format('truetype'),
  url('../fonts/roboto/roboto-regular-italic-400.svg') format('svg');
}

// Roboto Light
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/roboto/roboto-light-300.eot?#iefix');
  src: url('../fonts/roboto/roboto-light-300.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-light-300.woff2') format('woff2'),
  url('../fonts/roboto/roboto-light-300.woff') format('woff'),
  url('../fonts/roboto/roboto-light-300.ttf') format('truetype'),
  url('../fonts/roboto/roboto-light-300.svg') format('svg');
}

// Roboto Light Italic
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: italic;
  src: url('../fonts/roboto/roboto-light-italic-300.eot?#iefix');
  src: url('../fonts/roboto/roboto-light-italic-300.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-light-italic-300.woff2') format('woff2'),
  url('../fonts/roboto/roboto-light-italic-300.woff') format('woff'),
  url('../fonts/roboto/roboto-light-italic-300.ttf') format('truetype'),
  url('../fonts/roboto/roboto-light-italic-300.svg') format('svg');
}

// Roboto Bold
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/roboto/roboto-bold-700.eot?#iefix');
  src: url('../fonts/roboto/roboto-bold-700.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-bold-700.woff2') format('woff2'),
  url('../fonts/roboto/roboto-bold-700.woff') format('woff'),
  url('../fonts/roboto/roboto-bold-700.ttf') format('truetype'),
  url('../fonts/roboto/roboto-bold-700.svg') format('svg');
}

// Roboto Bold Italic
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/roboto/roboto-bold-italic-700.eot?#iefix');
  src: url('../fonts/roboto/roboto-bold-italic-700.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto/roboto-bold-italic-700.woff2') format('woff2'),
  url('../fonts/roboto/roboto-bold-italic-700.woff') format('woff'),
  url('../fonts/roboto/roboto-bold-italic-700.ttf') format('truetype'),
  url('../fonts/roboto/roboto-bold-italic-700.svg') format('svg');
}

// Montserrat Extra Bold
// Used for all bold number, scoreboard, count displays
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: normal;
  src: url('../fonts/montserrat/montserrat-extra-bold-800.eot?#iefix');
  src: url('../fonts/montserrat/montserrat-extra-bold-800.eot?#iefix') format('embedded-opentype'),
  url('../fonts/montserrat/montserrat-extra-bold-800.woff2') format('woff2'),
  url('../fonts/montserrat/montserrat-extra-bold-800.woff') format('woff'),
  url('../fonts/montserrat/montserrat-extra-bold-800.ttf') format('truetype'),
  url('../fonts/montserrat/montserrat-extra-bold-800.svg') format('svg');
}

// OpenDyslexic
@font-face {
  font-family: 'OpenDyslexic';
  src: url('../fonts/OpenDyslexic/OpenDyslexic-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'OpenDyslexic';
  font-weight: bold;
  src: url('../fonts/OpenDyslexic/OpenDyslexic-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'OpenDyslexic';
  font-weight: bold;
  font-style: italic;
  src: url('../fonts/OpenDyslexic/OpenDyslexic-Bold-Italic.woff2') format('woff2');
}
@font-face {
  font-family: 'OpenDyslexic';
  font-style: italic;
  src: url('../fonts/OpenDyslexic/OpenDyslexic-Italic.woff2') format('woff2');
}


// TYPEOGRAPHY MIXINS

// declare the font family using these shortcuts
@mixin font-roboto {font-family: 'Roboto', Arial, sans-serif !important;}
@mixin font-montserrat {font-family: 'Montserrat', Arial, sans-serif !important;}

// Declare font weights as a numerical value in rendered output
// Prevents certain browsers which do not play nice with "light, medium" textual declarations
// Numeical values always work more consistently across browsers
// Each font-weight is linked with the @font-face declaration to the actual font file
@mixin font-weight($weight) {
  @if $weight == 'light' {font-weight: 300;}
  @if $weight == 'normal' {font-weight: 400;}
  @if $weight == 'medium' {font-weight: 500;}
  @if $weight == 'bold' {font-weight: 700;}
  @if $weight == 'extrabold' {font-weight: 800;}
}

// Use these mixins to define font-size and line-height
// html and body declaration allows developer to pass px value as argument
// Rendered css will default to "rem" and fall back to "px" for unsupported browsers
@mixin font-size($size) {
  $rem: ($size / 10);
  $px: $size;
  font-size: #{$px + "px"};
  font-size: #{$rem + "rem"};
}
@mixin line-height($size) {
  $rem: ($size / 10);
  $px: $size;
  line-height: #{$px + "px"};
  line-height: #{$rem + "rem"};
}

// Soapbox icon font
@font-face {
  font-family: 'soapbox';
  src:  url('../fonts/soapbox/soapbox.eot?pryg6i');
  src:  url('../fonts/soapbox/soapbox.eot?pryg6i#iefix') format('embedded-opentype'),
    url('../fonts/soapbox/soapbox.ttf?pryg6i') format('truetype'),
    url('../fonts/soapbox/soapbox.woff?pryg6i') format('woff'),
    url('../fonts/soapbox/soapbox.svg?pryg6i#soapbox') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fa-users::before {
  font-family: 'ForkAwesome';
  content: '\f0c0';
}

.fa-fediverse::before {
  font-family: 'soapbox' !important;
  content: "\e901";
}
