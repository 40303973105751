.drawer {
  width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.drawer__tab {
  display: block;
  flex: 1 1 auto;
  padding: 15px 5px 13px;
  color: var(--primary-text-color--faint);
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  border-bottom: 2px solid transparent;
}

.column,
.drawer {
  flex: 1 1 100%;
  overflow: hidden;
}

.drawer__pager {
  box-sizing: border-box;
  padding: 0;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  display: flex;
}

.drawer__inner {
  top: 0;
  left: 0;
  background: var(--foreground-color);
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.pseudo-drawer {
  background: var(--background-color);
  font-size: 13px;
  text-align: left;
}

.drawer__header {
  flex: 0 0 auto;
  font-size: 16px;
  background: var(--brand-color--med);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

  a {
    transition: background 100ms ease-in;

    &:hover {
      background: var(--background-color);
      transition: background 200ms ease-out;
    }
  }
}

.drawer__backdrop {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($base-overlay-background, 0.5);
}
