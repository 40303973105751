.reply-indicator {
  border-radius: 4px;
  margin-bottom: 10px;
  background: var(--brand-color--faint);
  padding: 10px;
  min-height: 23px;
  overflow-y: auto;
  flex: 0 2 auto;
  max-height: 500px;
  @media screen and (min-width: 320px) and (max-width: 375px) {max-height: 220px;}
  @media screen and (max-width: 320px) {max-height: 130px;}
}

.reply-indicator__header {
  margin-bottom: 5px;
  overflow: hidden;
}

.reply-indicator__cancel {
  float: right;
  line-height: 24px;
}

.reply-indicator__display-name {
  color: var(--primary-text-color);
  display: block;
  max-width: 100%;
  line-height: 24px;
  overflow: hidden;
  padding-right: 25px;
  text-decoration: none;

  .display-name__account {
    color: var(--primary-text-color--faint);
  }
}

.reply-indicator__display-avatar {
  float: left;
  margin-right: 5px;
}

.reply-indicator__content {
  color: var(--primary-text-color);
  font-size: 14px;
  user-select: text;

  a {
    color: var(--highlight-text-color);
  }
}
