.badge {
  background-color: var(--brand-color);
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  padding: 2px 6px;
  border-radius: 2px;
  margin: 0 5px 5px 0;

  &--patron {
    background-color: #8a2be2;
    color: #fff;
  }

  &--admin {
    background-color: #000;
    color: #fff;
  }

  &--moderator {
    background-color: #048ba8;
    color: #fff;
  }
}
