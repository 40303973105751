.timeline-queue-header {
  display: block;
  width: 100%;
  max-height: 46px;
  position: relative;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: var(--brand-color--faint);
  transition: max-height 150ms ease;
  overflow: hidden;
  opacity: 1;

  &.hidden {
    max-height: 0;
    opacity: 0;
  }

  &__btn {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 46px;
    font-size: 14px;
    cursor: pointer;
    color: var(--primary-text-color);

    span {
      height: 46px;
    }
  }
}
